import React, { useState, useRef, useEffect } from "react";
import _ from "lodash"; // Ensure lodash is imported for throttling
import "./assets/timer.css";
import sound from "./assets/alarm-clock.mp3";
import image from "./assets/WORKFREAK_LOGO.png";
import arrow from "./assets/scroll-down-arrow.png";

const Timer = () => {
  const [time, setTime] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [lastYPosition, setLastYPosition] = useState(0);
  const [dragUpdatePaused, setDragUpdatePaused] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);

  const countdownInterval = useRef(null);
  const interactiveRef = useRef(null);
  const lastUpdateTime = useRef(Date.now());
  const endSound = useRef(new Audio(sound));
  const cumulativeMovement = useRef(0);

  useEffect(() => {
    const formatTime = () => {
      let minutes = Math.floor(time / 60);
      let seconds = time % 60;
      minutes = Math.min(minutes, 99);
      seconds = Math.min(seconds, 59);
      return `${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    };

    const formatTitle = () => {
      if (isCountdownActive && !isPaused) {
        return `${formatTime()}`;
      } else {
        return "Get Productive, FREE Online Digital Timer for Effective Work and More Free Time! Visit Workfreak.com for more details";
      }
    };

    document.title = formatTitle();
  }, [time, isCountdownActive, isPaused]);

  useEffect(() => {
    if (isCountdownActive && !isPaused && !countdownInterval.current) {
      countdownInterval.current = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          if (newTime <= 0) {
            clearInterval(countdownInterval.current);
            countdownInterval.current = null;

            setIsFlashing(true);
            setTimeout(() => {
              setIsFlashing(false);
              setIsCountdownActive(false); // Change pause to start after 3 seconds
            }, 3000);

            endSound.current.play();
            setTimeout(() => {
              endSound.current.pause();
              endSound.current.currentTime = 0;
            }, 3000);

            return 0;
          }
          return newTime;
        });
      }, 1000);
    }

    if ((isPaused || !isCountdownActive) && countdownInterval.current) {
      clearInterval(countdownInterval.current);
      countdownInterval.current = null;
    }

    return () => {
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
      }
    };
  }, [isCountdownActive, isPaused]);

  const formatTime = () => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    minutes = Math.min(minutes, 99);
    seconds = Math.min(seconds, 59);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  const handleStart = () => {
    setDragging(true);
    interactiveRef.current.style.cursor = "grabbing";
    lastUpdateTime.current = Date.now();
    setTime(time);
  };

  const handleMove = (positionY) => {
    if (!dragging || isCountdownActive || dragUpdatePaused) return;

    const currentY = positionY;
    cumulativeMovement.current += currentY - lastYPosition;

    const currentTime = Date.now();
    const timeElapsedSinceLastUpdate = currentTime - lastUpdateTime.current;

    const fastDragSpeedThreshold = 10; // Adjust as needed

    let timeChange =
      Math.abs(cumulativeMovement.current) > fastDragSpeedThreshold ? 60 : 5;
    const isMovingDown = cumulativeMovement.current > 0;

    let newTime = isMovingDown ? time - timeChange : time + timeChange;
    newTime = Math.max(0, Math.min(newTime, 5995));

    // Sticky values logic with pause on hit
    const stickyValues = [
      5 * 60,
      10 * 60,
      15 * 60,
      25 * 60,
      30 * 60,
      45 * 60,
      60 * 60,
    ];
    for (let stickyValue of stickyValues) {
      if (Math.abs(newTime - stickyValue) <= 15) {
        newTime = stickyValue;

        // Pause updating the timer when a sticky value is hit
        setDragUpdatePaused(true);
        setTimeout(() => setDragUpdatePaused(false), 300); // Pause for 0.5 seconds
        break;
      }
    }

    setTime(newTime);
    lastUpdateTime.current = currentTime;
    setLastYPosition(currentY);

    if (
      Math.abs(cumulativeMovement.current) > 10 ||
      timeElapsedSinceLastUpdate > 100
    ) {
      cumulativeMovement.current = 0;
    }
  };

  const handleEnd = () => {
    setDragging(false);
    interactiveRef.current.style.cursor = "grab";
  };

  const handleTouchStart = (event) => {
    if (event.touches.length > 0) {
      handleStart(event.touches[0].clientY);
    }
  };

  // Throttle touch move events to prevent overly sensitive responses
  const handleTouchMove = _.throttle((event) => {
    if (event.touches.length > 0 && dragging) {
      handleMove(event.touches[0].clientY);
    }
  }, 100); // Adjust the throttle time as needed

  const handleTouchEnd = () => {
    handleEnd();
  };

  const startCountdown = () => {
    if (time > 0 && !isCountdownActive) {
      setIsCountdownActive(true);
      setIsPaused(false);
    }
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const resumeTimer = () => {
    setIsPaused(false);
  };

  const resetTimer = () => {
    if (endSound.current) {
      endSound.current.pause();
      endSound.current.currentTime = 0;
    }

    clearInterval(countdownInterval.current);
    countdownInterval.current = null;
    setIsCountdownActive(false);
    setIsPaused(false);
    setTime(0);
    setIsFlashing(false);
  };

  const setPredefinedTime = (minutes) => {
    setTime(minutes * 60);
  };

  const flashClass = isFlashing ? "flash-animation" : "";

  return (
    <div className="app-display">
      <a href="https://workfreak.me" target="_blank" rel="noopener noreferrer">
        <img src={image} className="logo" alt="workfreak-logo" />
      </a>
      <div className="predefined-times">
        <button onClick={() => setPredefinedTime(5)}>5 Minutes</button>
        <button onClick={() => setPredefinedTime(10)}>10 Minutes</button>
        <button onClick={() => setPredefinedTime(25)}>25 Minutes</button>
        <button onClick={() => setPredefinedTime(45)}>45 Minutes</button>
        <button onClick={() => setPredefinedTime(60)}>60 Minutes</button>
      </div>
      <div className="timer-container">
        <div className="timer-circle">
          <div className="action-buttons">
            {isCountdownActive ? (
              <button
                className="start-button"
                onClick={isPaused ? resumeTimer : pauseTimer}
              >
                {isPaused ? "Resume" : "Pause"}
              </button>
            ) : (
              <button className="start-button" onClick={startCountdown}>
                Start
              </button>
            )}
            <button className="reset-button" onClick={resetTimer}>
              Reset
            </button>
          </div>
          <div
            className="interactive-area"
            ref={interactiveRef}
            onMouseDown={(e) => handleStart(e.clientY)}
            onMouseMove={(e) => handleMove(e.clientY)}
            onMouseUp={handleEnd}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img src={arrow} alt="arrow" className="scroll-up" />
            <img src={arrow} alt="arrow" className="scroll-down" />
            <div className="instruction-text">
              <div>Timing Area</div>
              <div>Drag up and down</div>
              <small>(slower drag for fine tune)</small>
            </div>
          </div>
          <span className={`timer-text ${flashClass}`}>{formatTime()}</span>
        </div>
      </div>
      <div
        // href="https://workfreak.me/products/productivity-timer"
        className="product-link"
        // rel="noopener noreferrer"
        target="_blank"
        style={{ bottom: 50 }}
      >
        GET PRODUTIVITY TIMER <br />
        <small>(Currently out of stock)</small>
      </div>

      <footer
        style={{
          position: "absolute",
          bottom: 5,
          width: "100%",
          textAlign: "center",
        }}
      >
        WORKFREAK LLC, 30 N Gould St Ste R, Sheridan, WY 82801, USA
      </footer>
    </div>
  );
};

export default Timer;
